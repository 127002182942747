import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, Grid, Paper } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ShowCard from "./ShowCard";
import ShowDetails from "./ShowDetails";
import { setupFirestoreEmulator, getShowImage, setupStorageEmulator, timestampToDate } from "./utils";
import { storage } from '../../firebase';

// Sets up the firestore database
setupFirestoreEmulator(db);
setupStorageEmulator(storage);

export default function Shows() {
    const location = useLocation();
    const [artist, setArtist] = React.useState(null);
    const [shows, setShows] = React.useState([]);
    const [selectedShow, setSelectedShow] = React.useState(null);

    // Queries Firestore for the all of the shows data
    const getShowsInfo = React.useCallback(async () => {
        try {
            // Loop over each returned show doc
            const d = await getDocs(collection(db, "shows"));
            d.forEach(show => {
                setShows(dat => [...dat, show.data()].sort((a, b) => {
                    const dateA = new Date(timestampToDate(a.date));
                    const dateB = new Date(timestampToDate(b.date));
                    return dateA - dateB;
                }).reverse());
            });
        } catch (error) {
            console.error("Error fetching artist data:", error);
        }
    }, []);

    // Hook used to fire the getShowsInfo function
    React.useEffect(() => {
        // If the shows are not loaded then load them
        if (!shows.length) getShowsInfo();

        // If the shows are loaded then check the URL for a showPath parameter
        if(shows.length) {
            const params = new URLSearchParams(location.search);
            const p_showPath = params.get("showPath");

            // rewrite the url to remove the song link parameters
            window.history.replaceState({}, document.title, window.location.pathname);

            if (p_showPath) {
                const show = shows.find(show => show.showPath === p_showPath);
                getShowImage(p_showPath, storage)
                .then(imageUrl => setSelectedShow({show: {...show, linkData: {
                    showPath: p_showPath,
                    song: params.get("song"),
                    track: params.get("track")
                }}, imageUrl}));
            } 
        }

    }, [shows, getShowsInfo, location.search]);

    // Hook to handle redirecting signed or unauthenticated users
    // React.useEffect(() => {
    //     if ( !userCtx.user ) navigate("/signin");
    // }, [userCtx.user, navigate]);

    // Hook Hack to intercept the back button
    React.useEffect(() => {
        const handlePopState = (event) => {
            // Prevent the default action of the back button
            window.history.pushState(null, document.title, window.location.href);
            console.log("Back button was pressed, but we ignored it.");
            setSelectedShow(null);
        };

        // Push a new state to prevent going back initially
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    /* React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const p_showPath = params.get("showPath");
        const p_song = params.get("song");
        const p_track = params.get("track");

        if (p_showPath) {
            console.log("Show Path: ", p_showPath);
            console.log("Song: ", p_song);
            console.log("Track: ", p_track);

            const show = shows.find(show => show.showPath === p_showPath);
            console.log("Show: ", show);

            getShowImage(p_showPath, storage)
            .then(url => {
                
                console.log("Download URL: ", url);



                // setSelectedShow({ showPath: p_showPath, imageUrl: url });
            });
            

        } else {
            console.log("No parameters set during page load.");
        }
    }, [location.search]); */

    return(
        <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", mb: 3, p:{xs: 0, sm: 2}}}>
                    {
                        !selectedShow ?
                            <Grid container>
                                <Typography variant="h4" sx={{pt:{xs:2, sm: 1},  pl:{xs:2, sm: 1}}}>
                                    { artist } Shows
                                </Typography>
                                <Grid container justifyContent="space-evenly" sx={{pb: 1}}>
                                    {
                                        shows && shows.map((show, idx) => {
                                            return (
                                                <Grid item key={idx}>
                                                    <ShowCard show={show} setSelectedShow={setSelectedShow} storage={storage}/>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        :
                            <ShowDetails data={selectedShow} setSelectedShow={setSelectedShow} />
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}
